@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
$footer-background-color: #fff;
$footer-border-color: #dcdcdc;
$footer-link-color: $text-color;
$footer-link-color-active: $text-color-super-darken;

.footer {
  background-color: $footer-background-color;
  padding: 0 $padding-default;
  margin-top: 30px;

  &__links-list {
    display: grid;
    grid-auto-columns: 1fr;
    grid-template-rows: repeat(16, min-content);
    grid-auto-flow: column;
    gap: 24px;
    margin-bottom: 24px;

    @include mq('mobile-l') {
      grid-template-rows: repeat(8, min-content);
      grid-auto-flow: column;
    }

    @include mq('desktop-s') {
      grid-template-rows: repeat(6, min-content);
      gap: 30px;
      margin-bottom: 30px;
    }

    @include mq('desktop') {
      grid-template-rows: repeat(4, min-content);
    }
  }

  &__section {
    border-top: 1px solid $footer-border-color;
    padding: 40px 0 10px;
  }

  &__title {
    letter-spacing: -1px;
    font-size: rem(20);
    color: $text-color-super-darken;
    margin-bottom: 24px;
    font-weight: 500;
    margin-top: 0;

    @include mq('desktop-s') {
      margin-bottom: 30px;
    }
  }

  &__subtitle {
    font-weight: 500;
    font-size: rem(16);
    line-height: 1.22;
    margin-top: 0;
    margin-bottom: 30px;
    color: $text-color-super-darken;
  }

  &__row {
    display: flex;
    flex-flow: row wrap;
  }

  &__column {
    flex-basis: 100%;
    margin-bottom: 30px;

    @include mq('mobile-l') {
      flex-basis: 50%;
    }

    @include mq('tablet') {
      flex-basis: 25%;
    }
  }

  &__text-link,
  a.footer__text-link {
    font-weight: 400;
    font-size: rem(16);
    line-height: 1.22;
    color: $footer-link-color;
    transition: color 0.15s $ease-out-quad;
    text-decoration: none;

    @include mq('desktop-s') {
      cursor: pointer;
    }

    &:hover {
      color: $footer-link-color-active;
    }
  }

  &__social {
    display: inline-flex;
    margin-left: -12px;
    margin-top: -5px;

    &-title {
      position: absolute;
      height: 1px;
      width: 1px;
      overflow: hidden;
      clip: rect(1px, 1px, 1px, 1px);
    }
  }

  &__social-icon {
    width: 30px;
    transition: fill 0.15s $ease-out-quad;
    cursor: pointer;
    margin-right: 20px;
    border-radius: 0;

    .icon-marmita {
      width: 26px;
      height: auto;
    }

    &.btn-icon.btn-icon--transparent {
      @include colorize-icon($text-color);

      svg,
      path {
        transition: all 0.15s $ease-out-quad;
      }
    }

    &:hover {
      &#{&}.btn-icon.btn-icon--transparent {
        @include colorize-icon($text-color-super-darken);
      }
    }
  }

  &__copyright-text {
    display: grid;
    align-items: center;
    grid-gap: 0 20px;
    grid-template-columns: 50px 1fr;
    grid-template-areas:
      'logo-link company-copy'
      'logo-link company-info';

    a {
      flex-basis: 64px;
      margin-right: 20px;
      min-width: 50px;
      grid-area: logo-link;
    }

    p {
      width: 100%;
      font-size: rem(11);
      line-height: 1.35;
      font-weight: 300;
      color: $text-color;
      margin: 0;

      @include mq('desktop-s') {
        font-size: rem(13);
      }
    }

    @include colorize-icon($primary-color);
  }

  &__home-link {
    position: absolute;
    height: 1px;
    width: 1px;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
  }

  &__company-informations {
    margin: 10px 0 0;
    font-size: rem(10);
    grid-area: company-info;
  }

  &__company-copyright {
    grid-area: company-copy;
  }

  &__section--aditional-items {
    border: none;
  }

  &__section--about,
  &__section--aditional-items {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 0 24px;

    .footer__column {
      margin-bottom: 20px;
    }

    @include mq('mobile-l') {
      grid-template-columns: repeat(2, 1fr);
    }

    @include mq('desktop-s') {
      grid-template-columns: repeat(3, 1fr);
      gap: 30px;
      margin-bottom: 0;

      .footer__column {
        margin-bottom: 0;
      }
    }

    @include mq('desktop') {
      grid-template-columns: repeat(4, 1fr);
    }

    .footer__links-list {
      grid-template-rows: auto;
      grid-auto-flow: row;
      grid-template-columns: 1fr;
      padding-bottom: 0;
    }
  }

  &__section--copyright {
    display: grid;
    grid-template-columns: auto;
    gap: 20px;
    padding: 40px 0;

    @include mq('tablet') {
      gap: 30px;
    }

    @include mq('desktop-s') {
      grid-template-columns: 1fr auto;
    }

    @include mq('desktop') {
      grid-auto-columns: min-content;
      grid-template-columns: 1fr 1fr;
      gap: 16px;
    }

    .footer {
      &__row {
        align-items: center;

        @include mq('desktop-s') {
          flex-wrap: nowrap;
          justify-content: space-between;
        }
      }

      &__terms-links {
        ul {
          display: grid;
          grid-template-columns: repeat(4, auto);
          grid-column-gap: 10px;

          @include mq('tablet', 'max') {
            grid-template-columns: auto;
          }
        }
      }

      &__text-link {
        display: inline-block;
        width: 100%;
        margin: 10px 0;
      }
    }
  }

  &__section--cities {
    display: grid;
    grid-template-areas:
      'title see-more'
      'list list';

    a.footer__text-link.footer__see-more {
      color: $primary-color;
      justify-self: end;
      margin-top: 0.2rem;
      font-weight: normal;
    }

    .footer__links-list {
      grid-area: list;
      grid-template-rows: repeat(19, min-content);
      grid-auto-columns: 1fr;

      @include mq('mobile-l') {
        grid-template-rows: repeat(10, min-content);
      }

      @include mq('desktop-s') {
        grid-template-rows: repeat(7, min-content);
      }

      @include mq('desktop') {
        grid-template-rows: repeat(5, min-content);
      }
    }
  }
}
